<template>
  <section id="session-expired">
    <div id="session-expired__content">
      <auth-forms-wrapper height="482px" width="448px">
        <template #default>
          <v-img
            class="mr-0"
            alt="Session Expired"
            :aspect-ratio="13 / 9"
            id="session-expired__image"
            :src="$images.sessionTimedOut"
          />
          <div class="pt-2 pb-6" id="session-expired__text">
            <h3 class="subtitle-1 text--primary font-weight-large text-center">
              {{ $dictionary.app.sessionExpired.title }}
            </h3>
          </div>
          <div class="form-group">
            <v-btn
              link
              block
              color="primary"
              :to="$paths.login"
              class="button font-weight-bold"
            >
              LOGIN AGAIN
            </v-btn>
          </div>
        </template>
      </auth-forms-wrapper>
    </div>
  </section>
</template>

<script>
import { AuthFormsWrapper } from "@/components/shared";

/**
 * View to be shown to the user when session is expired
 */
export default {
  name: "SessionExpired",
  /**
   * ---------- Components ------------
   */
  components: {
    AuthFormsWrapper,
  },
};
</script>
